import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 投诉须知
  {
    path: '/agreement/report',
    name: 'agreementreport',
    component: () => import('@/views/Agreement/ReportAgreement')
  },
  // 个人登录注册协议
  {
    path: '/agreement/loginPer',
    name: 'agreementloginPer',
    component: () => import('@/views/Agreement/LoginAgreementPer')
  },
  // 用户协议
  {
    path: '/agreement/user',
    name: 'agreementuser',
    component: () => import('@/views/Agreement/UserNotice')
  },
  // 查询须知
  {
    path: '/agreement/search',
    name: 'agreementsearch',
    component: () => import('@/views/Agreement/SearchNotice')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
